<template>
  <ServicesDescription
    :buttonText="buttonText"
    :isButtonIcon="isIcon"
    @connectService="getDocument()"
    :isFooterBtnLeft="isFooterBtnLeft"
  >
    <template #body>
      <!-- Шапка документа -->
      <b-row class="align-end">
        <b-col class="company-details__rowData dflex valign-end">
          <div>Директору {{ companyData.ShortName }}</div>
          <div>{{ companyData.ChiefFullNameDative }}</div>
          <span> от </span>
          <span>
            {{ customerData.Name }}
          </span>
          <div class="company-details__input_wrap">
            <BaseTextInput
              v-model="userFormData.LegalAddress"
              class="company-details__input"
              placeholder="Юридический адрес"
            />
          </div>
        </b-col>
      </b-row>
      <br />
      <br />
      <br />

      <!-- Тело заявления -->
      <b-row>
        <b-col class="statement-type">
          <p>{{ statementTitle }}</p>
        </b-col>
      </b-row>
      <div class="dflex valign-center">
        <span class="statement-text">
          Прошу рассмотреть возможность подключения дополнительной услуги
        </span>
        <div class="company-details__select_wrap">
          <BaseSelect v-model="serviceName" :options="serviceList" class="statement-body__select" />
        </div>
        <span class="statement-text">в рамках действующего договора об оказании услуг связи №</span>
        <div class="company-details__input_wrap">
          <BaseTextInput
            v-model="userFormData.ContractNumber"
            class="company-details__input"
            placeholder="Номер договора"
          />
        </div>
        <span class="statement-text">от</span>
        <div class="company-details__date_wrap">
          <BaseInputDate v-model="userFormData.ContractDate" class="company-details__date" />
        </div>
      </div>
      <br />

      <!-- Подпись -->
      <b-row>
        <b-col class="dflex align-end valign-center">
          <BaseTextInput
            v-model="userFormData.ChiefPosition"
            class="company-details__input"
            placeholder="Должность руководителя"
          />
          <span> ____________ / </span>
          <BaseTextInput
            v-model="userFormData.ChiefFullName"
            class="company-details__input"
            placeholder="ФИО руководителя"
          />
        </b-col>
      </b-row>
    </template>
  </ServicesDescription>
</template>

<script>
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import { mapState } from 'vuex';
import { getServiceName } from '@/additional_helpers/helpers';
import BaseSelect from '@/components/shared/inputs/BaseSelect.vue';
import BaseInputDate from '@/components/shared/inputs/BaseInputDate.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  // импортируется из ../StatementsCreation.vue
  inject: ['createDocument', 'isFooterBtnLeft', 'isIcon', 'buttonText'],

  props: {
    buttonText: {
      type: String
    }
  },

  data() {
    return {
      serviceName: 'Виртуальная АТС',
      statementTitle: 'Заявление',
      userFormData: {}
    };
  },

  components: {
    BaseTextInput,
    BaseInputDate,
    BaseSelect,
    ServicesDescription
  },

  // mounted() {
  //   this.setStatementName();
  // },

  watch: {
    serviceList: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal.length) this.setStatementName();
      }
    },

    customerData: {
      immediate: true,
      deep: true,
      handler() {
        if (this.customerData.ContractDate) {
          this.userFormData = {
            ContractNumber: this.customerData.ContractNumber,
            ContractDate: this.customerData.ContractDate.replace(/T00:00:00/, ''),
            ChiefPosition: this.customerData.ChiefPosition,
            ChiefFullName: this.customerData.ChiefFullName,
            LegalAddress: this.customerData.LegalAddress
          };
        }
      }
    }
  },

  computed: {
    ...mapState({
      companyData: (state) => state.statements.module.companyData,
      customerData: (state) => state.statements.module.customerData,
      serviceList: (state) => state.statements.module.serviceList
    })
  },

  methods: {
    // Формирует объект для отправки на сервер
    createModelToSend() {
      return {
        CurrentCustomer: {
          ContractNumber: this.userFormData.ContractNumber,
          ContractDate: `${this.userFormData.ContractDate}T00:00:00`,
          ChiefPosition: this.userFormData.ChiefPosition,
          ChiefFullName: this.userFormData.ChiefFullName,
          LegalAddress: this.userFormData.LegalAddress
        },
        ServiceName: this.serviceName
      };
    },

    getDocument() {
      const isValid = this.serviceName.length > 0 && this.userFormData.LegalAddress.length > 0;
      this.createDocument(this.createModelToSend(), isValid);
    },

    // Устанавливает название услуги, для отображения верной формы заявки
    // если переход был осуществлен с другого раздела
    setStatementName() {
      this.serviceName = getServiceName(
        this.serviceList,
        this.$route.query.service,
        this.serviceName
      );
    }
  }
};
</script>

<style></style>
